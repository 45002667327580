import "@styles/base/pages/page-misc.scss";

const ContextError = (error: { error: string }) => {
  const isProd = process.env.REACT_APP_ENV === "prod";
  return (
    <div className="misc-wrapper">
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">An error has occurred {isProd ? "" : "🫣"}</h2>
          <p className="mb-2">{error?.error}</p>
        </div>
      </div>
    </div>
  );
};

export default ContextError;
